<template>
  <div class="wrapper-download">
    <header>
      <img alt="Kuenz Naturbrennerei" src="../../assets/img/logo.png" title="Kuenz Naturbrennerei" />
      <FormCustomerLogout v-if="showImage" />
    </header>
    <div class="content-download">
      <ContentWelcomeDownload v-if="!showImage" />
      <FormCustomerHash :hash="customerHash" :showForm="!showImage" @onResponse="handleCustomerFormResponse" />
      <ImageCustomer :showImage="showImage" :image="customerImage" v-if="showImage"/>
    </div>
  </div>
</template>

<script>

import { ContentWelcomeDownload, FormCustomerHash, ImageCustomer, FormCustomerLogout } from '@/components/';
import { ImageController } from '@/controller';

export default {
  name: "Download",
  components: {
    ContentWelcomeDownload,
    FormCustomerHash,
    ImageCustomer,
    FormCustomerLogout
  },
  data() {
    return {
      showImage: false,
      customerImage: {
        identifier: '',
        basePath: ''
      },
      customerHash: {
        value: ""
      }
    }
  },
  created() {
    if (this.$route.params.hash) {
      console.log('download with hash', this.$route.params.hash);
      //this.fetchInvitation(this.$route.params.id);
    }
    this.customerHash.value = this.$route.params.hash;
    this.emitter.on("onCustomerLogout", () => this.handleCustomerLogout() );
  },
  methods: {
    handleCustomerFormResponse(success, responseData) {
      console.log('handleCustomerFormResponse', success, responseData);
      if (success) {
        this.showImage = ImageController.image != '';
        this.customerImage = ImageController.image;
      }
    },
    handleCustomerLogout() {
      console.log('customer logout');
      this.showImage = false;
    },
  },
};
</script>
